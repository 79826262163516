// _breakpoints.scss
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

@mixin sm-up {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl-up {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
