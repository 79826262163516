@import "/src/styles/mixins/rounded-padded";
@import "/src/styles/mixins/animate-autofill";

.component {
  border-top: var(--kp-border);
  position: relative;
  @include animate-autofill;

  h3 {
    padding: 0 1rem;
  }

  form {
    width: 100%;
    max-width: 42em;
    margin: 0 auto;

    div.stackable > div {
      display: flex;
      flex-direction: column;

      div[data-name=comments] {
        min-height: 5em;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    div[class^="MuiFormControl"] {
      margin-bottom: .6rem;
    }
  }


  & :global p.buttons {
    margin: 1em auto;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }

  p.confirmation {
    @include rounded-padded();
    background: chartreuse;
    border: var(--kp-border);
    text-align: center;

    strong {
      white-space: nowrap;
    }
  }

  pre {
    @include rounded-padded;
    background: #ffa100ee;
    border: var(--kp-border);
    font-size: small;
    max-width: 18rem;
    overflow: scroll;
    position: fixed;
    right: 1rem;
    text-align: left;
    top: 4rem;
    white-space: pre-wrap;
    width: max-content;
  }
}
