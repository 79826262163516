@import "src/styles/mixins/rounded-padded";
@import "src/styles/mixins/site-content";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(247,247,247,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

form {
  margin: 0 auto;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  max-width: 16em;
  padding: 1em;
}

textarea {
  box-sizing: border-box;
}

form select,
textarea,
form input {
  font-size: 1rem;
  border: var(--kp-input-border);
  border-radius: 4px;
  padding: 0.5em 1em;
}

form select {
  appearance: none;
  position: relative;
  background: transparent;
  z-index: 1;
}

form .input-block > label {
  font-weight: 500;
  margin-bottom: .2em;
}

form .input-block.select {
  position: relative;
}

form .input-block.select::after {
  content: "\25BC";
  position: absolute;
  right: .6em;
  top: 2em;
  color: var(--kp-button-primary-bg);
}

.App form p,
.input-block {
  margin-bottom: .8em;
}

.input-block:last-child {
  margin-bottom: 0;
}

a.button,
button {
  color: var(--kp-button-secondary-color);
  background: var(--kp-button-secondary-bg);
  border-width: var(--kp-border-width);
  border-style: outset;
  border-radius: 4px;
  padding: .5em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.button.action {
  background-color: darkturquoise;
}

button.primary:disabled,
button:disabled {
  background-color: #ddd;
  color: #333;
  cursor: not-allowed;
}

button.primary {
  color: var(--kp-button-primary-color);
  background: var(--kp-button-primary-bg);
}

.App form button {
  width: 100%;
}

.App form select {
  width: 100%;
}

.align-right {
  text-align: right;
}

.kp-multicolumn {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.kp-multicolumn > * {
  flex: 1;
}

.spin {
  animation: spin-animation 2s linear infinite;
}


.error {
  color: var(--mui-palette-error-main);
}

.success {
  color: lightgreen;
}

.stackable {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}

.stackable > * {
  width: 100%;
  flex-grow: 1;
  position: relative;
}

@media screen and (min-width: 762px) {
  .stackable {
    flex-direction: row;
    align-items: stretch;
  }
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 156, 255, 0.7);
  }
  50% {
    box-shadow: 0 0 4px 10px rgba(0, 156, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 1px rgba(0, 156, 255, 0.7);
  }
}

.pulsating {
  animation: pulsate 2s infinite;

  &.empty {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin: 2rem auto;
  }
}

.rounded-padded {
  @include rounded-padded();
}

menu.buttons {
  list-style: none;
  padding: 0;
}

.site-content {
  @include site-content;
}

@keyframes autofill {
  from {}
  to {}
}
