div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  & > header, footer {
    flex-grow: 0;
  }
  & > main {
    flex-grow: 1;
  }
}
