// LanguagePicker.module.scss
.component {
  :global span.lang-full {
    @media screen and (max-width: 449px) {
      display: none;
    }
  }

  :global span.lang-short {
    @media screen and (min-width: 450px) {
      display: none;
    }
  }
}
