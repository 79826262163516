// HomeTiles.module.scss
.component {
  & > * {
    max-width: 16rem;
  }

  article {
    padding-bottom: 3rem;
    position: relative;
    text-align: center;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
  }


  a {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
