@import "src/styles/mixins/site-content";

.component {
  background: #333333;
  color: white;
  text-align: center;
  font-size: 0.8rem;
  position: relative;

  :global svg {
    fill: #eee;
  }
  
  :global .language-chip {
    color: white;
  }

  a {
    color: #B2CCE5;
  }

  section {
    @include site-content;

    > div {
      margin: 1rem auto 0;
    }
  }

  :global button.log-in-out {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
