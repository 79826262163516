// Home.module.scss
//$bgImg: '/static/images/portrait-investment-advisor-businesswoman-sitting-260nw-428774503.jpg';
@import "/src/styles/mixins/rounded-padded";
@import "/src/styles/mixins/breakpoints";

.component {
  img {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
  }

  & :global div[class^=MuiCardContent] {
    padding: 1rem;
    position: relative;
    height: 100%;
    box-sizing: border-box;
  }

  & :global div[class^=MuiContainer] {
    position: relative;
    overflow: auto;
  }

  & :global div[class^=HomeTiles] {
    position: relative;
    z-index: 1;
    margin: 1.5rem auto 4rem auto;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
    width: fit-content;

    @include sm-up {
      grid-template-columns: repeat(2, 1fr);
    }

    @include md-up {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
