// DrawerMenu.module.scss
.DrawerMenu {
  height: 100%;
  position: relative;

  svg[class^=BlueCrossStar] {
    height: 2rem;
    width: 2rem;
  }

  section.bottom {
    bottom: 0;
    box-sizing: border-box;
    padding: 1rem;
    position: absolute;
    text-align: center;
    width: 100%;
  }
}
