@import "src/styles/mixins/site-content.scss";
@import "src/styles/mixins/breakpoints";

.component {
  background: #f6f6f6;
  position: relative;

  nav {
    @include site-content();
    align-items: center;
    display: flex;
    gap: 0;
    justify-content: space-between;

    @include sm-up() {
      gap: 2rem;
      justify-content: center;
    }

    a {
      white-space: nowrap;
    }
  }
}
