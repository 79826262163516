div.snackbars {
    position: fixed;
    z-index: 98;
    bottom: 1em;
    left: 1em;
    display: flex;
    flex-direction: column-reverse;
    gap: 1em;
    width: 62vw;
    max-width: 12em;
    transition: height 333ms ease-in;
    overflow: hidden;
}

div.snackbars > div {
    border-radius: .5em;
    background-color: #333e;
    padding: 1em;
    color: #039be5;
    z-index: 99;
    position: relative;
}
