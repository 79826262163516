@import '/src/styles/mixins/rounded-padded';

.component{

  div[class~="input-block"] {
    input {
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  p.noDoc {
    padding: 1rem;
    text-align: center;
  }
}
