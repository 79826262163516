// DaySlots.module.scss
.DaySlots {
  display: flex;
  justify-content: left;
  gap: 1rem;
  flex-wrap: wrap;

  :global .MuiChip-root {
    margin: 0.5rem 0 1rem 0;
  }

  :global .MuiChip-outlined .material-icons {
    opacity: 0.3;
  }
}
