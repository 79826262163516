@import '/src/styles/mixins/rounded-padded';

.component {
  width: fit-content;
  min-width: 350px;
  margin: auto;
  box-sizing: border-box;
  @include rounded-padded();

  & > div {
    @include rounded-padded();
  }

  div[class='slot-buttons'] {
    box-sizing: border-box;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    width: 100%;

    @media screen and (min-width: 450px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
