@import "/src/styles/mixins/rounded-padded";

.Booking {
  p.links {
    @include rounded-padded();
    background: blanchedalmond;
  }

  fieldset,
  legend
  {
    @include rounded-padded();
  }

  .bookingDetails {
    padding: 1rem;
  }
}
