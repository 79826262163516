@import '/src/styles/mixins/rounded-padded';
.component {
  width: 100%;
  max-width: 32rem;
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;

  & > div {
    @include rounded-padded();
  }

  div[data-kind=BookingCard] {
    margin-bottom: 1rem;
  }
}
