div.ModalScreen div.plaque {
    background: var(--kp-dialog-background);
    border: var(--kp-border);
    border-radius: 0.5rem;
    box-shadow: 2px 4px 6px 8px silver;
    box-sizing: border-box;
    padding: 0;
    width: max-content;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

div.ModalScreen div.plaque > * {
    margin: 0;
    padding: .5em 1em;
}

div.ModalScreen div.plaque > *:first-child {
    border-radius: 0.5rem 0.5rem 0 0;
}

div.ModalScreen div.plaque > *:last-child {
    border-radius: 0 0 0.5rem 0.5rem ;
}

div.ModalScreen div.plaque > h3,
div.ModalScreen div.plaque > menu {
    margin: 0;
    height: max-content;
    flex-grow: 0;
    background: var(--kp-dialog-bar-background);
}

div.ModalScreen div.plaque > section {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 5em;
    flex-grow: 1;
    border-width: 1px 0;
    border-style: solid;
    border-color: navy;
}

div.ModalScreen div.plaque > menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
    list-style: none;
}

div.ModalScreen div.plaque > menu button {
    font-size: 1.2rem;
}
