// BasicLayout.module.scss
@import "/src/styles/mixins/rounded-padded";
@import "/src/styles/mixins/breakpoints";

.BasicLayout {
  img {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
  }

  .children {
    max-width: 520px;
    position: relative;
    z-index: 1;
    margin: 16rem auto 4rem auto;
    text-align: justify;

    @include sm-up {
      margin-top: 18rem;
    }

    @include md-up {
      margin: 12em 0 4rem auto;
    }
  }

  section + div.children {
    margin-top: 0;
    @include sm-up {
      margin-top: 18rem;
    }

  }


  & :global div[class^=MuiContainer] {
    position: relative;
    overflow: hidden;
    min-height: 610px;
  }

  section {
    position: relative;
    width: fit-content;
    margin: 12rem auto 0;

    @include sm-up {
      margin: 0;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    @include md-up {
      position: fixed;
      left: 4rem;
      bottom: 2rem;
      margin: 0;
      max-width: 16rem;
      top: auto;
      z-index: 1;

      @media (min-height: 750px) {
        bottom: auto;
        top: 680px;
        transform: translate(0, -50%);
      }
    }

    @include lg-up {
      left: 50%;
      max-width: 32rem;
      transform: translate(-580px, 0);
      @media (min-height: 750px) {
        bottom: auto;
        top: 680px;
        transform: translate(-580px, -50%);
      }
    }

    @include xl-up {
      transform: translate(-690px, 0);
      @media (min-height: 750px) {
        bottom: auto;
        top: 680px;
        transform: translate(-690px, -50%);
      }

    }
  }

}
