@import "/src/styles/mixins/breakpoints";

.component {
  width: 100%;
  p:last-child {
    padding: 1rem;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(3, 1fr);

    @include sm-up {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
