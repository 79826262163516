// BlueCrossStar.module.scss
.BlueCrossStar {
  $starColor: #c00;
  fill: $starColor;
  stroke: $starColor;
  stroke-width: 0.1;
  transition: stroke, fill 222ms linear;

  &:hover {
    fill: currentColor;
    stroke: currentColor;
  }
}
